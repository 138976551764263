import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";

import { UtilService } from "./util.service";

import { environment } from "@env/environment";

import { UserActivityLog } from "@app/management/models/UserActivityLog";

import { ContentType } from "@app/shared/enums/content-type-enum";
import { Place } from "@app/shared/enums/place-enum";

@Injectable({
  providedIn: "root",
})
export class LogService {
  private MAX_BLUR_TIME = 1800;

  private log!: UserActivityLog;
  private startDate!: Date | null;
  private timeActive: number = 0;

  private url: string = environment.apiEndpoint + "/v2/user-logging/";

  private timer!: any;

  constructor(
    private router: Router,
    private utilService: UtilService,
    private http: HttpClient
  ) {
    window.addEventListener("focus", this.onFocus.bind(this));
    window.addEventListener("beforeunload", this.unloadHandler.bind(this));
  }

  startLog(place: Place, title: string, contentType?: ContentType, objectId?: number): void {
    this.startDate = new Date();
    this.timeActive = 0;
    /* 0 = Windows, 1 = Android, 2 = iOS */
    const device = this.utilService.getMobileOperatingSystem();

    /* 0 = browser, 1 = cordova */
    const application = this.utilService.checkIfCordova();

    this.http
      .post<any>(this.url, {
        url: this.router.url,
        place: place,
        place_title: title,
        device: device,
        application: application,
        object_id: objectId ? objectId : null,
        content_type: contentType ? contentType : null,
      })
      .subscribe({
        next: (log) => {
          this.log = log;
          this.timer = setInterval(this.updateLog.bind(this), 30000);
        },
      });
  }

  updateLog(): any {
    if (this.log) {
      this.timeActive += this.utilService.differenceInSeconds(this.startDate as Date, new Date());
      this.startDate = new Date();
      const body = {
        time_active: this.timeActive,
        updated_on: new Date(),
      };
      this.http.patch<any>(this.url + this.log.id, body).subscribe();
    }
  }

  finishLog(): any {
    if (this.log) {
      this.timeActive += this.utilService.differenceInSeconds(this.startDate as Date, new Date());
      this.startDate = null;
      const body = {
        time_active: this.timeActive,
        finished_on: new Date(),
      };
      this.http.patch<any>(this.url + this.log.id, body).subscribe({
        next: () => {
          this.timeActive = 0;
        },
      });
    }
    clearInterval(this.timer);
  }

  private onFocus(): void {
    const tempTime = this.utilService.differenceInSeconds(this.startDate as Date, new Date());
    this.startDate = new Date();

    if (tempTime < this.MAX_BLUR_TIME) {
      this.timeActive += tempTime;
    }
  }

  private unloadHandler() {
    this.finishLog();
  }
}
